import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/stepin/src/components/atoms/augmented-link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/stepin/src/components/navigation/footer/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/stepin/src/components/navigation/generic-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/stepin/src/components/navigation/promo-bar/promo-bar.client.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next-sanity@9.5.0_@sanity+client@6.22.1_@sanity+icons@2.11.8_react@18.3.1__@sanity+types@3.60_6s6zboefrekefsmfu44j4ey4p4/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/root-layout.tsx\",\"import\":\"Fredoka\",\"arguments\":[{\"weight\":[\"400\",\"600\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-fredoka\"}],\"variableName\":\"fredoka\"}");
